@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

// common
a {
  color: inherit;
  text-decoration: none;
}

//table
$point-color1: #606688;
$border-color: transparentize($point-color1, 0.5);
$border-height: 0.8px;

@mixin border-top-bottom {
  border-top: $border-height solid $border-color;
  border-bottom: $border-height solid $border-color;
}

@mixin border-bottom {
  border-bottom: $border-height solid $border-color;
}

table {
  text-align: center !important;
}

table,
tr,
td,
th {
  border: 0.1px solid $border-color;
}

iframe {
  display: none;
}

code {
  font-size: 0.9em !important;
}

/*page*/
.page_container {
  display: flex;
  flex-direction: column;
  max-width: 850px;
  margin: 0 auto;
  padding: 0 25px;
}
